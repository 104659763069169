/* @flow */

import {
  requestCreateProduct,
  requestUpdateProduct,
  uploadFirebaseImage,
  requestAddTypes,
  requestAddBrands,
  requestAddSuppliers,
} from './client';

import c from '../constants';

import {
  getCacheData,
  saveCacheData,
} from './products';

export function saveTypes(types: Array<string>): Function {
  return (dispatch: Function): Promise<any> => {
    return requestAddTypes(types).then((newTypesData) => {
      // Retrieve the current cache data
      const cacheData = getCacheData();

      // Update the cache with the new types
      const updatedTypes = cacheData.productTypes
        ? [...cacheData.productTypes, ...newTypesData] // Add new types to the existing list
        : [...newTypesData]; // If no types in the cache, create a new list

      // Save the updated cache data
      saveCacheData({
        productTypes: updatedTypes,
        productTypesDate: new Date().getTime(),
      });

      return updatedTypes;
    });
  };
}

export function saveBrands(brands: Array<Object>): Function {
  return (dispatch: Function): Promise<any> => {
    return requestAddBrands(brands).then((newBrandsData) => { // Usamos lo que devuelve requestAddBrands
      const cacheData = getCacheData(); // Obtener datos de caché existentes

      const updatedBrands = cacheData.productBrands
        ? [...cacheData.productBrands, ...newBrandsData] // Agregar las nuevas marcas devueltas
        : [...newBrandsData]; // Si no hay marcas en caché, crear una nueva lista

      // Actualizar el caché con las marcas actualizadas
      saveCacheData({
        productBrands: updatedBrands,
        productBrandsDate: new Date().getTime(),
      });

      return updatedBrands;
    });
  };
}



export function saveSuppliers(suppliers: Array<Object>): Function {
  return (dispatch: Function): Promise<any> => {
    return requestAddSuppliers(suppliers).then((newSuppliersData) => {
      // Retrieve the current cache data
      const cacheData = getCacheData();

      // Update the cache with the new suppliers
      const updatedSuppliers = cacheData.productSuppliers
        ? [...cacheData.productSuppliers, ...newSuppliersData] // Add new suppliers to the existing list
        : [...newSuppliersData]; // If no suppliers in the cache, create a new list

      // Save the updated cache data
      saveCacheData({
        productSuppliers: updatedSuppliers,
        productSuppliersDate: new Date().getTime(),
      });

      return updatedSuppliers;
    });
  };
}


export function uploadImage(file: Object, props: Object) {
  return uploadFirebaseImage(file, props);
}

export function getTagsFromName(name: string): Array<string> {
  const tags = {};
  name.split(' ').forEach((word1) => {
    word1.split(/,| |\/|-/).forEach((word) => {
      word = word.replaceAll('.', '')
        .replaceAll('#', '')
        .toLowerCase();
      tags[word] = true;
    });
  });
  // console.log('tags from name', Object.keys(tags));
  return Object.keys(tags).filter(o => (
    o && o.length > 1
  ));
}

export function createProduct(propsRaw: Object) {
  const props = {
    ...propsRaw,
    date: new Date(),
  };
  const newTags = getTagsFromName(propsRaw.name || '');
  newTags.forEach((word) => {
    if (!props.tags.includes(word)) {
      props.tags.push(word);
    }
  });
  return requestCreateProduct(props).catch((err) => {
    console.error(err);
  });
}

export function updateProduct(productId: string, propsRaw: Object): Function {
  return async (dispatch: Function, getState: Function): Promise<any> => {
    try {
      const newTags = getTagsFromName(propsRaw.name || '');
      newTags.forEach((word) => {
        if (!propsRaw.tags.includes(word)) {
          propsRaw.tags.push(word);
        }
      });
      const updatedProduct = await requestUpdateProduct(productId, propsRaw);
      dispatch({
        type: c.UPDATE_PRODUCT,
        payload: {
          id: productId,
          ...propsRaw,
        },
      });
      return updatedProduct;
    } catch (err) {
      console.error(err);
    }
  };
}

export function handleUploadImageFile(
  image:Object,
  progressFn:Function = () => {},
  options:Object = {},
): Promise<any> {
  return new Promise((resolve) => {
    import('fabric').then(({ fabric }) => {
      function fnUploadImage(fileImage:Object) {
        const preview = URL.createObjectURL(fileImage);
        uploadImage(fileImage, {
          folder: options.pathFolder || `${window.configClientKey}/products/original`,
          progressFn,
          forceId: options.userAvatar ? 'avatar' : null,
        }).then((newIdImage) => {
          resolve({
            id: newIdImage,
            image: fileImage,
            preview,
          });
        });
      }
      const canvas = new fabric.Canvas('canvasupload', {
        imageSmoothingEnabled: false,
        enableRetinaScaling: false,
      });
      fabric.Image.fromURL(URL.createObjectURL(image), (img) => {
        let imgWidth = img.width;
        let imgHeight = img.height;
        if (
          !options.userAvatar &&
          imgWidth <= 1200 &&
          imgHeight <= 1200
        ) {
          fnUploadImage(image);
        } else {
          const limitImg = options.userAvatar ? 200 : 1200;
          const fnMath = options.userAvatar ? Math.max : Math.min;
          const ratioSize = fnMath(
            limitImg / imgWidth,
            limitImg / imgHeight
          );
          img.set({
            top: 0,
            left: 0,
            width: imgWidth,
            height: imgHeight,
            scaleX: ratioSize,
            scaleY: ratioSize,
          });
          if (options.userAvatar) {
            canvas.setWidth(200);
            canvas.setHeight(200);
            img.top = (200 - imgHeight * ratioSize) / 2;
            img.left = (200 - imgWidth * ratioSize) / 2;
          } else {
            canvas.setWidth(img.getScaledWidth());
            canvas.setHeight(img.getScaledHeight());
          }
          canvas.add(img);
          canvas.renderAll();
          canvas.lowerCanvasEl.toBlob((canvasBlob) => {
            fnUploadImage(canvasBlob);
          }, 'image/jpeg', 1);
        }
      });
    });
  });
}
