/* @flow */

import React, { Fragment, Suspense, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useRoutes,
  useNavigate,
} from 'react-router-dom';

import useToaster from 'rsuite/useToaster';
import Notification from 'rsuite/Notification';

import { loadConfig } from './config';
import { registerAuthStateChanged } from './actions/account';
import { loadCacheData } from './actions/products';

import Enter from './components/Enter';
import Header from './components/Header';
import Home from './components/Home';
import Product from './components/Product';

const Cart = React.lazy(() => import('./components/Cart'));
const Checkout = React.lazy(() => import('./components/Checkout'));
const Account = React.lazy(() => import('./components/Account'));
const Search = React.lazy(() => import('./components/Search'));
const MyOrders = React.lazy(() => import('./components/MyOrders'));
const About = React.lazy(() => import('./components/About'));
const Politics = React.lazy(() => import('./components/Politics'));
const CreateProduct = React.lazy(() => import('./components/CreateProduct'));
const EditProduct = React.lazy(() => import('./components/CreateProduct/EditProduct'));
const Statistics = React.lazy(() => import('./components/Statistics'));
const Favorites = React.lazy(() => import('./components/Favorites'));
const CreateBranch = React.lazy(() => import('./components/CreateBranch'));
const Branches = React.lazy(() => import('./components/Branches'));
const Order = React.lazy(() => import('./components/Order'));
const Orders = React.lazy(() => import('./components/Orders'));
const Admin = React.lazy(() => import('./components/Admin'));
const VipCatalog = React.lazy(() => import('./components/VipCatalog'));

type AppProps = {
  // Define the type for the props parameter here
};

loadCacheData();

function App(props: AppProps): ReactNode {
  const dispatch = useDispatch();
  const user = useSelector(state => state.account.user);
  const toaster = useToaster();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.uid) {
      const todayDate = new Date();
      if (
        user.birthdate &&
        todayDate.getUTCDate() === user.birthdate?.getUTCDate() &&
        todayDate.getUTCMonth() === user.birthdate?.getUTCMonth() &&
        !window.birthdayNotification
      ) {
        window.birthdayNotification = true;
        toaster.push(
          <Notification type="info" header="Feliz cumpleaños" closable>
            <p>¡Hoy es tu cumpleaños! 🎉</p>
          </Notification>,
          { placement: 'topEnd',duration: 0  }
        );
      }
    }
  }, [user?.uid]);

  useEffect(() => {
    loadConfig(); // TODO: move logic to a function for index.html
    const registerTime = Date.now();
    dispatch(registerAuthStateChanged((
      message,
      orderData,
    ) => {
      if (Date.now() - registerTime < 5000) {
        return;
      }
      toaster.push(
        <Notification type="info" header="Alerta de orden" closable>
          <p>{message}</p>
          <span
            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
            onClick={() => navigate(`/orders/${orderData.id}`)}
          >
            {orderData.data.orderNumber}
          </span>
        </Notification>,
        { placement: 'topEnd',duration: 0  }
      );
    }));
  }, []);

  const routesArray = [
    { path: "/enter", element: <Enter /> },
    { path: "/create-product", element: <CreateProduct /> },
    { path: "/edit-product/:productId", element: <EditProduct /> },
    { path: '/statistics', element: <Statistics /> },
    { path: '/account', element: <Account /> },
    { path: '/cart', element: <Cart /> },
    { path: '/search', element: <Search /> },
    {path: '/vip-catalog', element: <VipCatalog />},
    { path: '/favorites', element: <Favorites /> },
    { path: '/create-branch', element: <CreateBranch /> },
    { path: '/branches', element: <Branches /> },
    { path: '/my-orders', element: <MyOrders /> },
    { path: '/product/:productId', element: <Product /> },
    { path: '/orders/:orderId', element: <Order /> },
    { path: '/orders', element: <Orders /> },
    { path: '/cart/checkout', element: <Checkout /> },
    { path: '/about', element: <About /> },
    { path: '/politics', element: <Politics /> },
    { path: '/admin', element: <Admin /> },
    {
      path: "/",
      element: <Home />,
    },
  ];
  if (process.env.NODE_ENV === 'development') {
    const Dev = React.lazy(() => import('./components/Dev'));
    routesArray.push({ path: '/dev', element: <Dev /> });
  }
  let element = useRoutes(routesArray);

  return (
    <Fragment>
      <Header />
      <Suspense fallback={<div></div>}>
        <div className="mainscroll">
          {element}
        </div>
      </Suspense>
    </Fragment>
  );
}

export default App;
