/* @flow */

import {
    requestAddSale,
    requestGetSales,
    requestGetSalesWithIds,
    requestPayDue,
    requestUpdateCashRegister,
  } from './client';

import deepMerge from 'lodash/merge';
import c from '../constants';

import {
  getCacheData,
  saveCacheData,
} from './products';


export const addSale = (saleData) => {
    return async (dispatch, getState): Promise<any> => {
      try {
        const response = await requestAddSale(saleData);
        const cacheData = getCacheData();
        const newSale = { id: response.id, data: response.data };
        const updatedSales = cacheData.sales ? [...cacheData.sales, newSale] : [newSale];
        dispatch({ type: c.UPDATE_SALES, payload: updatedSales });
        saveCacheData({ 
          sales: updatedSales,
          salesDate: new Date().getTime(),
        });
        if (response.updatedUserData) {
            const updatedUsers = cacheData.users.map(user =>
              user.id === saleData.userId 
                ? { ...user, data: { ...user.data, ...response.updatedUserData } }
                : user
            );
            dispatch({ type: c.UPDATE_USERS, payload: updatedUsers });
            saveCacheData({ users: updatedUsers, usersDate: new Date().getTime() });
          }
        const state = getState();
        const userId = state.account.user.uid;
        const oldCashRegister = deepMerge({}, state.client.cashRegister);
        const isOpenBranch = oldCashRegister[saleData.branch][userId]?.isOpen || false;
        if(isOpenBranch){
          const oldAmount = parseFloat(oldCashRegister[saleData.branch][userId]?.amount) || 0;
          const newData = {
            ...oldCashRegister,
            [saleData.branch]: {
              [userId]: {
                amount:oldAmount + parseFloat(saleData.total) - parseFloat(saleData.due),
                isOpen:oldCashRegister[saleData.branch][userId].isOpen,
                sales: [...oldCashRegister[saleData.branch][userId].sales, newSale.id]
              },
            }
            
            }
          const cashRegisterData = await requestUpdateCashRegister(newData);
          dispatch({
            type: c.UPDATE_CASH_REGISTER,
            payload: cashRegisterData,
          });
        }
  
        return response;
      } catch (error) {
        console.error('Error creando venta:', error);
        throw error;
      }
    };
  };

  export function getSales(props:Object = {}):Function {
    return async (dispatch:Function) => {
      const cacheData = getCacheData();
      if (
        !(props.startDate || props.endDate) &&
        cacheData.sales &&
        cacheData.salesDate > new Date().getTime() - 1000 * 60 * 10 // 10 minutos
      ) {
        dispatch({
          type: c.UPDATE_SALES,
          payload: cacheData.sales,
        });
        return cacheData.sales;
      }
      try {
        const salesData = await requestGetSales(props);
        dispatch({
          type: c.UPDATE_SALES,
          payload: salesData,
        });
        saveCacheData({
          sales: salesData,
          salesDate: new Date().getTime(),
        });
        return salesData;
      } catch (error) {
        console.error('Error obteniendo ventas:', error);
        throw error;
      }
    };
  }


  export function getSalesWithIds(salesIds): Function {
    return async (dispatch: Function) => {
      try {
        const salesData = await requestGetSalesWithIds(salesIds);
        dispatch({
          type: 'GET_USER_SALES',
          payload: salesData,
        });
        return salesData;
      } catch (error) {
        console.error('Error obteniendo ventas:', error);
        throw error;
      }
    };
  }

  export const payDue = (payDueData) => {
    return async (dispatch: Function): Promise<any> => {
      try {
        const response = await requestPayDue(payDueData);
        const cacheData = getCacheData();
  
        const updatedSales = cacheData.sales.map(sale =>
          sale.id === response.id
            ? { ...sale, data: { ...sale.data, ...response.data } }
            : sale
        );
  
        dispatch({ type: c.UPDATE_SALES, payload: updatedSales });
        saveCacheData({
          sales: updatedSales,
          salesDate: new Date().getTime(),
        });
        const updatedUsers = cacheData.users.map(user =>
            user.id === payDueData.userId
              ? { ...user, data: { ...user.data, due: response.data.due } }
              : user
        );
  
          dispatch({ type: c.UPDATE_USERS, payload: updatedUsers });
          saveCacheData({ users: updatedUsers, usersDate: new Date().getTime() });
      
  
        return response;
      } catch (error) {
        console.error('Error actualizando venta:', error);
        throw error;
      }
    };
  };
  